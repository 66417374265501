<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
          >Latest Sales</span
        >
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
          >
            <thead>
              <tr class="text-left">
                <th style="min-width: 150px" class="pl-7">
                  <span class="text-dark-75">Client Name</span>
                </th>
                <th style="min-width: 220px">Policy Sold</th>
                <th style="min-width: 100px">Date</th>
                <th style="min-width: 100px">Premium</th>
                <th style="min-width: 50px"></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="quote in quotes">
                <tr v-bind:key="quote._id">
                  <td class="pl-1 pt-8">
                    <div class="d-flex align-items-center">
                      <div>
                        <span
                          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                          >{{ quote.primaryMember.firstName || "Unknown" }} {{ quote.primaryMember.lastName || "Unknown" }}</span
                        >
                        <span class="text-muted font-weight-bold d-block">{{ quote.primaryMember.email || "No email" }}</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <b-row class="align-items-center">
                      <b-col cols="4">
                        <span class="symbol-label">
                          <img
                            src="http://alphaapi.rapid-quote.co.uk/assets/img/providers/exeter.png"
                            class="w-100 align-self-center"
                            alt=""
                          />
                        </span>
                      </b-col>
                      <b-col>
                        <span class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Health+</span>
                      </b-col>
                    </b-row>
                  </td>
                  <td>
                    <span
                      class="text-muted d-block font-size-lg"
                      >{{ quote.dateQuoted }}</span
                    >
                    <span class="text-muted font-weight-bold"></span>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >£100.01</span
                    >
                    <span class="text-muted font-weight-bold d-block">monthly</span>
                  </td>
                  <td class="pr-0 text-right">
                    <b-button
                      class="p-3 btn btn-default font-weight-bolder font-size-sm"
                      >
                      <b-icon icon="pencil-square" font-scale="1"></b-icon>
                      </b-button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<script>
const moment = require('moment');
// const LEAD_SIZE = 5;

export default {
  name: "sales-dashboard",
  data () {
    return {
      isLoading: false,
      clientDetailsKeys: [
        { key: "title", label: "Title" },
        { key: "firstName", label: "First Name" },
        { key: "lastName", label: "Last Name" },
        { key: "gender", label: "Gender" },
        { key: "telephoneNumber", label: "Telephone No." },
        { key: "dateOfBirth", label: "Date of Birth" },
        { key: "postcode", label: "Postcode" },
        { key: "isSmoker", label: "Smoker" },
      ],
      coverDetailsKeys: [
        { key: "underwriting", label: "Underwriting" },
        { key: "coverage", label: "Coverage" }
      ],
      tagOptions: [
        { value: "New Lead", text: "New Lead" },
        { value: "No Reply", text: "No Reply" },
        { value: "Invalid No.", text: "Invalid No." },
        { value: "Contacted", text: "Contacted" },
        { value: "Quoted", text: "Quoted" },
        { value: "Emailed", text: "Emailed" },
        { value: "Not Interested", text: "Not Interested" },
        { value: "Sold", text: "Sold" },
        { value: "In Underwriting", text: "In Underwriting" },
        { value: "Not Taken Up", text: "Not Taken Up" },
        { value: "Awaiting Cert", text: "Awaiting Cert" },
      ],
      paymentOptions: [
        { value: "monthly", text: "Monthly" },
        { value: "annual", text: "Annual" }
      ]
    }
  },
  computed: {
    quotes: {
      get() {
        var sales = [{
          "coverage": "basic",
          "underwriting": "NMORI",
          "tag": "New Lead",
          "coverType": "Single",
          "convertedToSale": false,
          "dependentMembers": [],
          "_id": "6061f97c0a12ef3ff9614665",
          "excessLevel": 0,
          "coverStartDate": "2021-03-29T00:00:00.000Z",
          "dateQuoted": "2021-03-29T15:59:56.914Z",
          "updatedAt": "2021-03-29T15:59:56.914Z",
          "__v": 0,
          "primaryMember": {
            "gender": "male",
            "relationship": "main",
            "_id": "6061f97c0a12ef3ff9614666",
            "title": "mr",
            "firstName": "",
            "lastName": "",
            "dateOfBirth": "1988-01-01T00:00:00.000Z",
            "yearsInsured": 2,
            "yearsClaimFree": 0,
            "numberOfClaims": "0",
            "telephoneNumber": "",
            "email": "",
            "postcode": "GL51 0EX",
            "address1": "",
            "address2": "",
            "town": "",
            "county": "",
            "isSmoker": false,
            "__v": 0
          }
        }, {
          "coverage": "basic",
          "underwriting": "NMORI",
          "tag": "New Lead",
          "coverType": "Single",
          "convertedToSale": false,
          "dependentMembers": [],
          "_id": "6061f2439d37803ca7ec0b18",
          "excessLevel": 0,
          "coverStartDate": "2021-03-29T00:00:00.000Z",
          "dateQuoted": "2021-03-29T15:29:07.041Z",
          "updatedAt": "2021-03-29T15:29:07.041Z",
          "__v": 0,
          "primaryMember": {
            "gender": "male",
            "relationship": "main",
            "_id": "6061f2439d37803ca7ec0b19",
            "title": "mr",
            "firstName": "",
            "lastName": "",
            "dateOfBirth": "1988-01-01T00:00:00.000Z",
            "yearsInsured": 2,
            "yearsClaimFree": 0,
            "numberOfClaims": "0",
            "telephoneNumber": "",
            "email": "",
            "postcode": "GL51 0EX",
            "address1": "",
            "address2": "",
            "town": "",
            "county": "",
            "isSmoker": false,
            "__v": 0
          }
        }, {
          "coverage": "basic",
          "underwriting": "NMORI",
          "tag": "New Lead",
          "coverType": "Single",
          "convertedToSale": false,
          "dependentMembers": [],
          "_id": "6061f23c9d37803ca7ec0b08",
          "excessLevel": 0,
          "coverStartDate": "2021-03-29T00:00:00.000Z",
          "dateQuoted": "2021-03-29T15:29:00.825Z",
          "updatedAt": "2021-03-29T15:29:00.825Z",
          "__v": 0,
          "primaryMember": {
            "gender": "male",
            "relationship": "main",
            "_id": "6061f23c9d37803ca7ec0b09",
            "title": "mr",
            "firstName": "",
            "lastName": "",
            "dateOfBirth": "1988-01-01T00:00:00.000Z",
            "yearsInsured": 2,
            "yearsClaimFree": 0,
            "numberOfClaims": "0",
            "telephoneNumber": "",
            "email": "",
            "postcode": "GL51 0EX",
            "address1": "",
            "address2": "",
            "town": "",
            "county": "",
            "isSmoker": false,
            "__v": 0
          }
        }, {
          "coverage": "basic",
          "underwriting": "NMORI",
          "tag": "New Lead",
          "coverType": "Single",
          "convertedToSale": false,
          "dependentMembers": [],
          "_id": "6061f23c9d37803ca7ec0b081",
          "excessLevel": 0,
          "coverStartDate": "2021-03-29T00:00:00.000Z",
          "dateQuoted": "2021-03-29T15:29:00.825Z",
          "updatedAt": "2021-03-29T15:29:00.825Z",
          "__v": 0,
          "primaryMember": {
            "gender": "male",
            "relationship": "main",
            "_id": "6061f23c9d37803ca7ec0b09",
            "title": "mr",
            "firstName": "",
            "lastName": "",
            "dateOfBirth": "1988-01-01T00:00:00.000Z",
            "yearsInsured": 2,
            "yearsClaimFree": 0,
            "numberOfClaims": "0",
            "telephoneNumber": "",
            "email": "",
            "postcode": "GL51 0EX",
            "address1": "",
            "address2": "",
            "town": "",
            "county": "",
            "isSmoker": false,
            "__v": 0
          }
        }, {
          "coverage": "basic",
          "underwriting": "NMORI",
          "tag": "New Lead",
          "coverType": "Single",
          "convertedToSale": false,
          "dependentMembers": [],
          "_id": "6061f23c9d37803ca7ec0b082",
          "excessLevel": 0,
          "coverStartDate": "2021-03-29T00:00:00.000Z",
          "dateQuoted": "2021-03-29T15:29:00.825Z",
          "updatedAt": "2021-03-29T15:29:00.825Z",
          "__v": 0,
          "primaryMember": {
            "gender": "male",
            "relationship": "main",
            "_id": "6061f23c9d37803ca7ec0b09",
            "title": "mr",
            "firstName": "",
            "lastName": "",
            "dateOfBirth": "1988-01-01T00:00:00.000Z",
            "yearsInsured": 2,
            "yearsClaimFree": 0,
            "numberOfClaims": "0",
            "telephoneNumber": "",
            "email": "",
            "postcode": "GL51 0EX",
            "address1": "",
            "address2": "",
            "town": "",
            "county": "",
            "isSmoker": false,
            "__v": 0
          }
        }];


        return sales.map(item => {
          item.primaryMember.dateOfBirth = moment(item.primaryMember.dateOfBirth).format('DD/MM/YYYY');
          item.primaryMember.isSmoker = item.primaryMember.isSmoker ? "Yes" : "No";
          item.dateQuoted = moment(item.dateQuoted).format("DD/MM/YYYY h:mm:ss");

          return item;
        });
      }
    }
  },
  methods: {
    generateRecap: function(coverDetails){
      let recapData = [];
      
      switch(coverDetails.underwriting){
        case "NMORI":
          recapData.push("Mori");
          break;
        case "CPME":
          recapData.push("CPME");
          break;
        case "CMORI":
          recapData.push("CMori");
          break;
        case "FMU":
          recapData.push("FMU");
          break;  
      }

      switch(coverDetails.coverage){
        case "basic":
          recapData.push("No OP");
          break;
        case "intermediate":
          recapData.push("Limited OP");
          break;
        case "full":
          recapData.push("Full OP");
          break;
      }

      return recapData;
    },
    leadClass: function(leadTag){
      return {
        'tag-new-lead' : leadTag == "New Lead",
        'tag-no-reply' : leadTag == "No Reply",
        'tag-invalid-no' : leadTag == "Invalid No.",
        'tag-contacted' : leadTag == "Contacted",
        'tag-quoted' : leadTag == "Quoted",
        'tag-emailed' : leadTag == "Emailed",
        'tag-interested' : leadTag == "Not Interested",
        'tag-sold' : leadTag == "Sold",
        'tag-in-underwriting' : leadTag == "In Underwriting",
        'tag-awaiting-cert' : leadTag == "Awaiting Cert",
        'tag-not-taken' : leadTag == "Not Taken Up"
      }
    }
  },
  mounted () {
    // // Initial load of quotes
    // this.$store.dispatch("getQuotes")
    // .then(() => {})
    // .catch(() => {});

    // // Load insurers
    // this.$store.dispatch("getInsurers");
  }
};
</script>

<style>
.lead-popover {
  min-width: 550px;
}
</style>